@import '~materialize-css/dist/css/materialize.min.css';

body {
  background: #131313;
  color     : #ddd;
}

input.dark-input {
  border-bottom: 1px solid #ffffff90 !important;
  color        : #fff !important;
  opacity      : 0.9;
}

input.dark-input:focus {
  opacity: 1;
}

input.dark-input+label {
  color  : #fff !important;
  opacity: 0.9;
}

input.dark-input:focus+label {
  opacity: 1;
}

.nav-wrapper {
  padding: 0 2rem;
}